import { Carousel } from './types'

export const getOrientation = (ref: { current: Element | null }) => {
  const el = ref.current
  if (el && el.scrollHeight > el.clientHeight) {
    return 'vertical'
  } else {
    return 'horizontal'
  }
}

export const measureScroller = (ref: { current: Element | null }) => {
  const orientation = getOrientation(ref)
  const el = ref.current
  const children = el ? Array.from(el.children) : []
  const inView: Element[] = []
  const offViewStart: Element[] = []
  const offViewEnd: Element[] = []

  if (!el) return { children, inView, offViewStart, offViewEnd }

  // Get container boundaries to calculate which items are currently viewable
  // Note: adding/subtracting pixels for rounding discrepancies
  const parentRect = el.getBoundingClientRect()
  const parentStart =
    orientation === 'horizontal' ? parentRect.left - 1 : parentRect.top - 1
  const parentEnd =
    orientation === 'horizontal' ? parentRect.right + 1 : parentRect.bottom + 1

  // Determine which children are viewable in container by comparing the
  // boundaries of each child with parent boundaries.
  children.forEach((el) => {
    const { left, right, top, bottom } = el.getBoundingClientRect()
    const start = orientation === 'horizontal' ? left : top
    const end = orientation === 'horizontal' ? right : bottom
    if (start < parentStart) offViewStart.push(el)
    if (end > parentEnd) offViewEnd.push(el)
    if (start >= parentStart && end <= parentEnd) inView.push(el)
  })

  return { children, inView, offViewStart, offViewEnd }
}

export const defaultState = {
  children: [],
  inView: [],
  offViewStart: [],
  offViewEnd: [],
  canScrollBack: false,
  canScrollForward: false,
  pages: 1,
  currentPage: 1,
}

export const defaultContext: Carousel = {
  ...defaultState,
  orientation: 'horizontal',
  scrollBack: () => null,
  scrollForward: () => null,
  scrollToIndex: () => null,
}
