import React from 'react'
import { ICampaignTag } from 'src/types/generated/contentful'
import { Type } from '@/components/common'

interface Props {
  tags?: ICampaignTag[]
  className?: string
}

export const CampaignTags: React.FC<Props> = ({ tags = [], className }) => {
  const validTags = tags.filter((t) => !!t.fields)
  if (!validTags.length) return null

  return (
    <ul className={className}>
      {validTags.map(({ fields, sys }) => (
        <Type
          as="li"
          key={sys.id}
          variant="eyebrow-sm"
          className="mr-2 inline-flex items-center rounded bg-core-gray-200 p-2 text-core-gray-700 last:mr-0"
        >
          {fields.title}
        </Type>
      ))}
    </ul>
  )
}

export default CampaignTags
