import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Button } from '@/components/common'
import { Props as ButtonProps } from '@/components/common/Button/Button'

type PifButtonProps = ButtonProps & {
  url: string
}

const PifButton = ({ url, ...rest }: PifButtonProps) => {
  const router = useRouter()
  const handleClick = useCallback(async () => {
    router.push(url)
  }, [router, url])

  return (
    <Button onClick={handleClick} {...rest}>
      <div className="flex items-center justify-center">
        <span>Pay it Forward</span>
      </div>
    </Button>
  )
}

export default PifButton
