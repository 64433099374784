import React from 'react'
import classNames from 'classnames'
import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'

interface Props extends Campaign {
  theme?: 'light' | 'dark'
  className?: string
}

const CampaignHeading: React.FC<Props> = ({
  status,
  name,
  slug,
  offeringString,
  theme = 'light',
  className = '',
}) => {
  const { campaignAssets } = useCampaignAssets(slug)

  let label
  if (status === 'testing_the_waters') {
    label = 'Express Interest in'
  } else {
    label =
      status === 'currently_funding' ? 'Now Funding' : 'Not Currently Funding'
  }

  return (
    <div
      className={classNames(
        {
          'text-white': theme === 'light',
          'text-core-gray-950': theme !== 'light',
        },
        className
      )}
    >
      <Type
        as="p"
        variant="caption-lg"
        className="mb-2"
        data-cy="funding-status-heading"
      >
        <span>{label}</span>
        <span>{` | ${offeringString}`}</span>
      </Type>
      <Type
        as="h2"
        variant="heading-xs"
        className="font-semibold"
        data-cy="project-name-header"
      >
        {name}
      </Type>
      {campaignAssets?.subtitle && (
        <Type as="p" variant="paragraph-md" className="mt-1">
          {campaignAssets.subtitle}
        </Type>
      )}
    </div>
  )
}

export default CampaignHeading
