import { getCampaign, Campaign } from '@/services/api/campaign'
import { whiteLabelUtil } from '@/services/api/siteAssets'
import useApi from '@/services/hooks/useApiV2'
import useSiteAssets from '../useSiteAssets/useSiteAssets'

const useCampaign = (slug?: string) => {
  const { domain } = useSiteAssets()

  const {
    data: campaign,
    isLoading,
    ...rest
  } = useApi<Campaign>({
    key: slug ? `campaign/${slug}` : false,
    request: () =>
      getCampaign(slug as string).then((c) => {
        if (!whiteLabelUtil.isAllowed(c, domain)) {
          throw new Error('Invalid campaign')
        }

        return c
      }),
  })

  return {
    campaign,
    isLoading: !slug ? false : isLoading,
    ...rest,
  }
}

export default useCampaign
