import React from 'react'
import classNames from 'classnames'
import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import { useIncrementer } from '@/services/hooks/useIncrementer'
import useSiteAssets from '@/services/hooks/useSiteAssets'

interface Props {
  campaign: Campaign
  className?: string
}

const Backers: React.FC<Props> = ({ campaign, className }) => {
  const { backers, priorRaiseTotal, progressString, status } = campaign
  const siteAssets = useSiteAssets()

  const resolvedBackers = siteAssets?.usePriorRaisesLayout
    ? priorRaiseTotal.backers + backers
    : backers

  const incrementingBackers = Math.round(useIncrementer(resolvedBackers))

  return (
    <div className={className}>
      <div className="flex flex-row items-center">
        <Type
          as="div"
          variant={
            status === 'testing_the_waters' ? 'title-md' : 'paragraph-lg'
          }
          data-cy="backers_number"
          className={classNames('whitespace-nowrap', {
            'font-bold': status === 'testing_the_waters',
          })}
        >
          {status === 'testing_the_waters'
            ? incrementingBackers.toLocaleString()
            : resolvedBackers.toLocaleString()}{' '}
          People*
        </Type>
      </div>
      {status === 'testing_the_waters' && (
        <Type as="div" variant="paragraph-sm">
          {progressString}
        </Type>
      )}
    </div>
  )
}

export default Backers
