import { useEffect, useState } from 'react'

export const useIncrementer = (
  initialValue: number,
  initialValueMultiplier = 0.75
) => {
  const startingValue = initialValue * initialValueMultiplier
  const [value, setValue] = useState(startingValue)

  useEffect(() => {
    // increment displayAmount until it reaches the resolvedAmount
    const interval = setInterval(() => {
      setValue((prevDisplayAmount) => {
        const updatedAmount = prevDisplayAmount + prevDisplayAmount * 0.01

        // if the startingValue changes while this interval is running, this will reset it
        // this is to avoid the issue where the build may pass in a lower number than what is current on the server (causing the animation to increment slowly)
        // For example, a campaign that is selling fast may have had a much lower starting value during the build
        if (updatedAmount < startingValue) {
          return startingValue
        }
        if (updatedAmount < initialValue) {
          return updatedAmount
        }

        // stop incrementing when displayAmount reaches resolvedAmount
        clearInterval(interval)
        return initialValue
      })
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [initialValue, startingValue])

  return value
}
