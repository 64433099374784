import React, { useCallback } from 'react'
import { Button } from '@/components/common'
import { Props as ButtonProps } from '@/components/common/Button/Button'
import { Checkmark } from '@/components/svg'
import { useExperiment } from '@/experimentation'
import SegmentHandler from '@/services/analytics/SegmentHandler'
import {
  Campaign,
  userFollowCampaign,
  userUnfollowCampaign,
} from '@/services/api/campaign'
import useAuth from '@/services/hooks/useAuth'
import useFollowingCampaign from '@/services/hooks/useFollowingCampaign/useFollowingCampaign'

export interface Props extends Omit<ButtonProps, 'color'> {
  campaign: Campaign
  onClickCallback?: () => void
  color: 'oxide' | 'copper'
}

const FollowButton = ({ campaign, onClickCallback, ...rest }: Props) => {
  const { slug } = campaign
  const { user, openModal, startAuth } = useAuth()
  const { isFollowing, mutate } = useFollowingCampaign(slug)
  const [isOAuth2] = useExperiment('ellis_island_oauth2', true)

  const handleClick = useCallback(async () => {
    if (!user) {
      if (isOAuth2) {
        startAuth()
      } else {
        openModal('login')
      }
      return
    }

    if (isFollowing) {
      await userUnfollowCampaign(slug)
      SegmentHandler.track('User Unsubscribed', {
        project: slug,
      })
    } else {
      await userFollowCampaign(slug)
      SegmentHandler.track('User Subscribed', {
        project: slug,
      })
    }

    await mutate()

    onClickCallback?.()
  }, [
    user,
    isFollowing,
    mutate,
    onClickCallback,
    isOAuth2,
    startAuth,
    openModal,
    slug,
  ])

  return (
    <Button
      onClick={handleClick}
      variant={isFollowing ? 'secondary' : 'primary'}
      {...rest}
    >
      <span className="flex items-center justify-center">
        {isFollowing && <Checkmark className="mr-2 h-5 w-5" />}
        <span>
          {isFollowing ? 'Subscribed to Updates' : 'Subscribe to Updates'}
        </span>
      </span>
    </Button>
  )
}

export default FollowButton
