import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import { useIncrementer } from '@/services/hooks/useIncrementer'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { formatMoney } from '@/utils'

export interface Props {
  campaign: Campaign
  className?: string
}

export const AmountRaised: React.FC<Props> = ({ campaign, className }) => {
  const { amountRaised, priorRaiseTotal, status } = campaign
  const siteAssets = useSiteAssets()

  const isTowardGoal =
    !siteAssets?.usePriorRaisesLayout &&
    campaign.stretchGoal &&
    campaign.stretchGoals.length > 1

  const resolvedAmount = siteAssets?.usePriorRaisesLayout
    ? priorRaiseTotal.amount + amountRaised
    : amountRaised

  const incrementingAmount = useIncrementer(resolvedAmount)

  const resolvedProgressString = siteAssets?.usePriorRaisesLayout
    ? `of ${formatMoney(siteAssets.priorRaisesGoalAmount, {
        zeroFractionDigits: true,
      })}`
    : isTowardGoal
    ? campaign.progressWithGoalString
    : campaign.progressString

  return (
    <div className={className}>
      <Type
        as="span"
        variant="title-md"
        className="font-bold"
        data-cy="capital-amount"
      >
        {formatMoney(
          status === 'currently_funding' ? incrementingAmount : resolvedAmount,
          {
            zeroFractionDigits: true,
          }
        )}
      </Type>
      <Type as="span" variant="title-sm">
        {' '}
        {resolvedProgressString}*
      </Type>
    </div>
  )
}

export default AmountRaised
