import React, { LegacyRef } from 'react'
import Markdown from '@/components/Markdown'
import { Type } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import InvestButton from './InvestButton'
import PifButton from './PifButton'

export interface Props {
  campaign: Campaign
  investButtonWrapperRef?: LegacyRef<HTMLDivElement>
  className?: string
}

const PriorRaisesInvestCard: React.FC<Props> = ({
  campaign,
  investButtonWrapperRef,
  className,
}) => {
  const { regulationTypeLabel, status } = campaign
  const siteAssets = useSiteAssets()

  const heading =
    status === 'currently_funding'
      ? `Currently Funding - Regulation ${regulationTypeLabel} Round`
      : `Regulation ${regulationTypeLabel} Round`
  return (
    <div className={className}>
      <div ref={investButtonWrapperRef} className="mb-4">
        <InvestButton
          campaign={campaign}
          className="mx-auto block w-full max-w-[420px]"
          sectionName="body"
          color="oxide"
        />
      </div>

      <Type
        as="h3"
        variant="title-sm"
        className="mb-4 font-semibold md:text-center"
      >
        {heading}
      </Type>

      <Type as="div" variant="paragraph-lg">
        <Markdown source={siteAssets?.priorRaisesDisclaimer} />
      </Type>

      {siteAssets?.pifUrl && (
        <PifButton
          url={siteAssets.pifUrl}
          variant="secondary"
          className="mx-auto mt-4 block w-full max-w-[420px]"
        />
      )}
    </div>
  )
}

export default PriorRaisesInvestCard
