import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import { ButtonBase, Type } from '@/components/common'
import { ChevronRight } from '@/components/svg'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { formatMoney } from '@/utils'
import StretchGoalModal from './StretchGoalsModal'

interface Props {
  campaign: Campaign
  theme?: 'light' | 'dark'
}

const StretchGoal: React.FC<Props> = ({ campaign, theme = 'dark' }) => {
  const [modalIsOpen, setModalOpen] = useState(false)
  const { stretchGoal } = campaign
  const siteAssets = useSiteAssets()

  const showEnhancedStretchGoals =
    !siteAssets?.usePriorRaisesLayout &&
    campaign.stretchGoal &&
    campaign.stretchGoals.length > 1

  const goalText = useMemo(() => {
    if (siteAssets?.usePriorRaisesLayout) {
      return siteAssets.priorRaisesTitle
    }

    if (stretchGoal && showEnhancedStretchGoals) {
      return stretchGoal.title
    }

    if (stretchGoal) {
      return `${stretchGoal.title} ${formatMoney(stretchGoal.amount, {
        zeroFractionDigits: true,
      })}`
    }

    return null
  }, [siteAssets, stretchGoal, showEnhancedStretchGoals])

  if (!goalText) return null

  if (showEnhancedStretchGoals && stretchGoal) {
    return (
      <>
        <div className="mb-1 flex justify-between gap-2">
          <Type
            as="div"
            variant="caption-sm"
            className={classNames('w-full', {
              'text-white': theme === 'light',
              'text-core-gray-950': theme === 'dark',
            })}
          >
            {goalText}
          </Type>
          <ButtonBase
            className={classNames('flex shrink-0 items-center', {
              'text-oxide-3 hover:text-oxide': theme === 'light',
              'text-core-oxide-core hover:text-core-oxide-bright':
                theme === 'dark',
            })}
            type="button"
            onClick={() => setModalOpen(true)}
          >
            <Type as="span" variant="caption-sm" className="font-bold">
              View Goals
            </Type>
            <ChevronRight className="w-3" strokeWidth={3} />
          </ButtonBase>
        </div>

        <StretchGoalModal
          isOpen={modalIsOpen}
          setIsOpen={setModalOpen}
          campaign={campaign}
        />
      </>
    )
  }

  return (
    <Type
      as="div"
      variant="caption-sm"
      className={classNames('mb-1 w-full', {
        'text-white': theme === 'light',
        'text-core-gray-950': theme === 'dark',
      })}
    >
      {goalText}
    </Type>
  )
}

export default StretchGoal
