import React from 'react'
import { Text } from '@/components/common'
import { Campaign } from '@/services/api/campaign'
import useCampaignAssets from '@/services/hooks/useCampaignAssets'
import { formatMoney } from '@/utils'

interface Props {
  campaign: Campaign
  className?: string
}

const InvestmentDetails: React.FC<Props> = ({ campaign, className }) => {
  const perShare = formatMoney(campaign.sharePrice)
  const { campaignAssets } = useCampaignAssets(campaign.slug)

  if (!campaignAssets?.valuation) return null

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <Text
          as="div"
          preset="heading.eyebrow"
          className="flex-1 uppercase leading-none"
        >
          Investment Details:
        </Text>

        <div className="ml-8 flex-grow-0">
          <Text as="div" preset="body.md">
            {perShare}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Per Share
          </Text>
        </div>

        <div className="ml-8 flex-[0_3.75rem]">
          <Text as="div" preset="body.md">
            {campaignAssets.valuation}
          </Text>
          <Text as="div" preset="body.xs" className="uppercase">
            Pre-Money Valuation
          </Text>
        </div>
      </div>
    </div>
  )
}

export default InvestmentDetails
